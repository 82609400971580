.receive {
  &-card {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5vh;
  }

  &-button {
    border-width: 0;

    > span {
      position: relative;
    }

    &::before {
      content: '';
      background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%);
      position: absolute;
      inset: 0;
      opacity: 1;
      transition: all 0.3s;
      border-radius: inherit;
    }

    &:hover::before {
      opacity: 0;
    }
  }
}
